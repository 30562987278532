<template>
  <div id="app">
    <div v-if="this.recipe != null">
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span>工艺信息</span>
        <el-button style="float: right; display:none; padding: 3px 0" type="text">操作按钮</el-button>
      </div>
      <el-row >
        <table>
          <tr>
            <td width="15%">
            来源: 
            </td>
            <td width="25%">
              {{ this.recipe.srcStr }}
            </td>
            <td width="20%">
              名称: 
            </td>
            <td>
              {{ this.recipe.name }}
            </td>
          </tr>
          <tr>
          <td>
            茶型: 
            </td>
            <td>
              {{ this.recipe.teaTypeStr }}
            </td>
            <td>
              香型: 
            </td>
            <td>
              {{ this.recipe.taste }}
            </td>
          </tr>
        </table>
        </el-row>
    </el-card >
    <div style="margin-top: 5px;"></div>
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span>工艺步骤</span>
        <el-button style="float: right; display:none; padding: 3px 0" type="text">操作按钮</el-button>
      </div>
      <span >此次做茶工序所需时长：{{ this.recipe.timeStr }}</span>
      <div style="height:10px"></div>
    <div v-if="this.recipe.standard" >
      <div v-for="item in this.recipe.steps" v-bind:key="item.key">
        <div class="standardtech">
          <el-row>{{ item.name }}</el-row>
          <el-row>{{ item.timeStr }}</el-row>
        </div>
        <div class="stdtech" v-if="item.arrow" ><el-image
            class="iconcls"
            :fit="fit"
            :src="imgUrl"
          /></div>
        
      </div>
    </div>
    <div v-else>
      <div v-for="item in this.recipe.groups" v-bind:key="item.key" class="techgoup"> 
      
        <div class="groupline"></div>
  
        <el-row  :gutter="24">
          <el-col :span="10" style="text-align: left;  margin-top:6px">
          工序名称
          </el-col>
          <el-col :span="12" style="text-align: right;">
          <el-button  type="text" @click.native.prevent="handleSwichShow(item)" >{{ item.name }} </el-button>
          <i v-if="item.show" class="el-icon-arrow-up"></i>
          <i v-else class="el-icon-arrow-down"></i>
        </el-col>
        </el-row>
        <div v-if="item.show" style="margin-top:10px">
          <div class="grouphead"></div>
          <el-row class="techlist">
            子工序 
            <el-button v-for="tech in item.techs" v-bind:key="tech.key" size="small" round @click.native.prevent="handleShowTech(item, tech)">{{ tech.name }}</el-button>
          </el-row>
          <div v-if="item.target != null" class="techDetailline">
            <el-row >
              <el-col :span="12" class="lefthead">
                温度（ ℃ ）
              </el-col>
              <el-col :span="12" class="tailline">
                {{ item.target.tmp }}
              </el-col>
            </el-row>
            <div class="sepeterline"></div>
            <el-row >
              <el-col :span="12"  class="lefthead">
                湿度（ %Rh ）
              </el-col>
              <el-col :span="12"  class="tailline">
                {{ item.target.hmd }}
              </el-col>
            </el-row>
            <div class="sepeterline"></div>
            <el-row>
              <el-col :span="12" class="lefthead">转笼速度（ % ） </el-col>
              <el-col :span="12" class="tailline">
                {{ item.target.cspd }}
              </el-col>
            </el-row>
            <div class="sepeterline"></div>
            <el-row>
            <el-col :span="12" class="lefthead">加热风量（ % ）
              </el-col>
              <el-col :span="12" class="tailline">
                {{ item.target.cspd }}
              </el-col>
            </el-row>
            <div class="sepeterline"></div>
            <el-row>
            <el-col :span="12" class="lefthead">循环风量（ % ）
              </el-col>
              <el-col :span="12" class="tailline">
                {{ item.target.cspd }}
              </el-col>
            </el-row>
            <div class="sepeterline"></div>
          <el-row>
            <el-col :span="12"  class="lefthead">时长
            </el-col>
            <el-col :span="12" class="tailline">
            {{ item.target.timeStr }}
            </el-col>
          </el-row>
            <div class="sepeterline"></div>
          <el-row>
            <el-col :span="8" class="lefthead">加热方式
            </el-col>
            <el-col :span="8">
                <el-checkbox v-model="item.target.heat"  class="techcheck">电热管加热</el-checkbox>
            </el-col>
            <el-col :span="8" class="tailline">
                <el-checkbox v-model="item.target.infrared"  class="techcheck">红外管加热</el-checkbox>
            </el-col>
          </el-row>
          </div>
        </div>
      
      <div style="height: 10px"></div>
      </div>
    </div>
    </el-card>
  </div>
  <div v-else>
    无工艺信息
  </div>
  <div class="dlapp">
  <el-button type="success" class="goapp" round @click="jumpToDownload">下载一起玩茶APP体验更多</el-button>
  </div>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  name: 'Share',
  components: {
  },
  created() {
    document.title = '工艺分享'
    this.getRecipeInfo()
  },
  data() {

    return {
      recipe: null,
      imgUrl: require("../assets/nextgroup.png")
    }
  },
  methods: {
    jumpToDownload(){
      window.location.href = 'https://www.pgyer.com/x9hI'
    },
    handleSwichShow(item) {
      item.show = !item.show
    },
    handleShowTech(item, tech) {
      item.target = tech
    },
    timeToStr(timeValue) {
      if (timeValue >= 3600) {
          return parseInt(timeValue/3600)+"小时"+parseInt(timeValue/60)%60 + "分"
        } else {
          return parseInt(timeValue/60) + "分"
        }
    },
    getRecipeInfo() {
      let source = this.$route.query.source
      let rid = this.$route.query.recipe
      axios({
        url: "api/share/recipe",
        method: "get",
        baseURL: "https://test.totea.cn/",
        params: {
          recipeId: rid,
          source: source
        },
      timeout: 5000
      }).then(response => {
        var recipeInfo = response.data.data;
        if (recipeInfo.source === 0) {
          recipeInfo.srcStr = '自定义工艺'
        } else if (recipeInfo.source === 1) {
          recipeInfo.srcStr = '设备工艺'
        } else if (recipeInfo.source === 2) {
          recipeInfo.srcStr = '标准工艺'
        } else if (recipeInfo.source === 3) {
          recipeInfo.srcStr = '收藏夹工艺'
        }  else {
          recipeInfo.srcStr = "-"
        }
        recipeInfo.timeStr = this.timeToStr(recipeInfo.totalTime)
        
        var index = 0
        if (recipeInfo.steps != null) {
          recipeInfo.steps.forEach(item => {
            item.arrow = true
            item.key = index++
            item.timeStr = this.timeToStr(item.time)
          })

          var last = recipeInfo.steps[recipeInfo.steps.length - 1]
          last.arrow = false
        }
        if (recipeInfo.groups != null) {
          recipeInfo.groups.forEach(item => {
            item.show = false
            item.key = index++
            item.timeStr = this.timeToStr(item.prd)
            var techNo = 1
            if (item.techs != null && item.techs.length > 0) {
              item.target = item.techs[0]
              item.techs.forEach(tech => {
                tech.name = item.name + techNo
                techNo++
                tech.key = index++
                })
            }
          })

        }
        this.recipe = recipeInfo
        this.$forceUpdate()
      })
    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 20px;
}
  .text {
    font-size: 14px;
  }

  .item {
    margin-bottom: 18px;
  }

  .clearfix:before,
  .clearfix:after {
    display: table;
    content: "";
  }
  .clearfix:after {
    clear: both
  }

  .box-card {
    width: 360px;
  }
  .groupline {
    background-color: rgba(235, 235, 235, 1);
    border-radius: 2px;
    width: 300px;
    height: 1px;
    margin: 0px 0 10px 16px;

  }

  .grouphead {
    background-color: rgba(235, 235, 235, 1);
    border-radius: 2px;
    width: 300px;
    height: 1px;

  }

  .lefthead {
    padding-left: 12px;
    text-align: left;
  }
  .techmid{
    padding-right: 3px;
  }
  .tailline {
    text-align: right;
    padding-right: 12px;
  }
  .textleft {
    text-align: left;
  }
  .textright {
    text-align: right;
  }
  .techDetailline {
    width: 280px;
    margin-top: 8px;
    margin-left: 18px;
    padding-top: 8px;
    padding-bottom: 8px;
    background-color: #FBFBFB;
  }
  .sepeterline {
    background-color: rgba(235, 235, 235, 1);
    border-radius: 2px;
    width: 260px;
    height: 1px;
    margin: 6px 0 0 10px;
  }
  .techhead {
    text-align: left;
    width: 40%
  }
  .techname {
    padding-top: 10px;
    padding-left: 10px;
    text-align: left;
  }
  .techlist {
    margin-top:10px;
    padding-left: 5px;
    text-align: left;
  }
  .techgroup {
  }
  .dlapp {
    margin-top:10px;
    width: 360px;
    text-align: center;
  }
  .goapp {
    width:90%;
  }
  .techcheck {
    pointer-events: none;
    cursor: default;
  }

  .standardtech {
    background-color: rgba(246, 252, 249, 1);
    border-radius: 6px;
    height: 70px;
    border: 1px solid rgba(101, 202, 119, 1);
    width: 160px;
    margin: 10px 0 0 68px;
    padding-top: 15px;
  }
  .iconcls {
    padding-top: 15px;
    width: 26px;
    height: 24px;
  }
  .stdtech {
    width: 300px;
    text-align: center;
  }
  .updownicon {

    float: right;  
  }
</style>
